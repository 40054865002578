import React from "react";
import { Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const sliderItem = [
    { img: require("../assets/img/partners/client-2-1.svg").default },
    { img: require("../assets/img/partners/client-2-2.svg").default },
    { img: require("../assets/img/partners/client-2-3.svg").default },
    { img: require("../assets/img/partners/client-2-4.svg").default },
    { img: require("../assets/img/partners/client-2-1.svg").default },
    { img: require("../assets/img/partners/client-2-2.svg").default },
    { img: require("../assets/img/partners/client-2-3.svg").default },
    { img: require("../assets/img/partners/client-2-4.svg").default },
  ];

  return (
    <section className="partners">
      <Container>
        <Row>
          <span className="subtitle">partners</span>
          <h4>Backed By Leading Blockchain Investors And Founders</h4>
          <Slider {...settings} className="slider">
            {sliderItem.map((item, i) => (
              <div key={i}>
                <img src={item.img} alt="" />
              </div>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
