import React from "react";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <img src={require("../assets/img/logo.png")} className="logo" alt="" />
        <p>
          Lorem Ipsum is simply dummy text of the printing <br /> and
          typesetting industry. Lorem Ipsum has been the industry's.
        </p>
        <div className="social">
          <a href="/">
            <img
              src={require("../assets/img/social/facebook.svg").default}
              alt=""
            />
          </a>
          <a href="/">
            <img
              src={require("../assets/img/social/instagram.svg").default}
              alt=""
            />
          </a>
          <a href="/">
            <img
              src={require("../assets/img/social/linked.svg").default}
              alt=""
            />
          </a>
          <a href="/">
            <img
              src={require("../assets/img/social/twitter.svg").default}
              alt=""
            />
          </a>
        </div>
        <div className="copy">Copyright © 2024 IKO. All rights reserved.</div>
      </div>
    </section>
  );
};

export default Footer;
