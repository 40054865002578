import React from "react";
import { Container } from "react-bootstrap";

const CTA = () => {
  return (
    <section className="cta">
      <Container>
        <div className="box">
          <div className="details">
            <h4>Be part of the future</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.
            </p>
            <div className="btn-block">
              <a href="#about" className="btn-st btn3">
                <span>Get Started Today</span>
              </a>
            </div>
          </div>
          <div className="images">
            <img src={require("../assets/img/rocket.jpg")} alt="" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CTA;
