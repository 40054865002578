import React from "react";

const NavBar = () => {
  return (
    <nav className="navbar-custom">
      <div className="container">
        <div className="left-side">
          <a href="/" className="logo">
            <img
              className="logo"
              src={require("../assets/img/logo.png")}
              alt=""
            />
          </a>
        </div>
        <div className="info">
          <a href="#about" className="btn-st btn2">
            <span>Connect Wallet</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
